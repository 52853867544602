// Dependency
import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";

// Components
import Video from "../video";

// Styles
import * as backgroundCardStyles from './background-card.module.scss'

// Template
export default function BackgroundCard(props) {

    console.log(props.data.link)
    const link = props.country === 'fr' && props.data.link.includes('eu') ? props.data.link.replace('eu', 'fr') : props.data.link

    return (
        <div className={`${backgroundCardStyles.cdBackgroundCardWrapper} cd-background-card-wrapper`}>
            <div className={backgroundCardStyles.cdBackgroundCard}
                onMouseOver={props.data.video ? event => event.target.closest('.cd-background-card-wrapper').querySelector("video").play() : null}
                onMouseOut={props.data.video ? event => event.target.closest('.cd-background-card-wrapper').querySelector("video").pause() : null}
            >
                <div className={`cd-background-img`}>
                    {
                        props.data.video && props.data.video.url ? <div><Video poster={props.data.featuredMedia?.url} videoSrcURL={props.data.video?.url} /></div>
                            : <Img alt="" fluid={props.data.featuredMedia?.fluid} />
                    }
                </div>
                <div className={backgroundCardStyles.cdBackgroundCardContent}>
                    <h3>{props.data.title}</h3>
                    <div>
                        <Link to={`/${link}/`} className="cd-absolute-link" ></Link>
                    </div>
                </div>
            </div>
        </div>

    )
}
